import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppSettings, defaults } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

export const baseUrl = 'https://billyangu.com:7209/';
// export const baseUrl = 'http://localhost:8000/';

export const endpoint = baseUrl+'api/';
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private http: HttpClient,public router:Router){
  }
  get notify(): Observable<Record<string, any>> {
    return this.notify$.asObservable();
  }


  private extractData(res:any) {
    const  body = res;
    return body || { };
  }
  downloadFile(url: string, filename: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, filename);
    }, error => {
      console.error('Download error ss:', error);
    });
  }

  login(data:any):Observable<any>{
    return this.http.post(endpoint + 'login/',data).pipe(
      map(this.extractData));
  }

  
  
  getProfile():Observable<any>{
    return this.http.get(endpoint + 'user_details/',).pipe(
      map(this.extractData));
  }

  
  getRentalPaymentReport():Observable<any>{
    return this.http.get(endpoint + 'payment_reporting/',).pipe(
      map(this.extractData));
  }

  addOwner(data:any):Observable<any>{
    return this.http.post(endpoint + 'house_owners/',data).pipe(
      map(this.extractData));
  }
  getOwners(data:any):Observable<any>{
    return this.http.get(endpoint + 'house_owners/'+data).pipe(
      map(this.extractData));
  }

  getGeneralReport():Observable<any>{
    return this.http.get(endpoint + 'general-report/').pipe(
      map(this.extractData));
  }
  getRevenueReport():Observable<any>{
    return this.http.get(endpoint + 'revenue_report/').pipe(
      map(this.extractData));
  }
  getPaymentMonthlyReport():Observable<any>{
    return this.http.get(endpoint + 'payments_overall_report/').pipe(
      map(this.extractData));
  }


  getDebtReport():Observable<any>{
    return this.http.get(endpoint + 'debt_report/').pipe(
      map(this.extractData));
  }
  getTermsheetReport():Observable<any>{
    return this.http.get(endpoint + 'termsheet_report/').pipe(
      map(this.extractData));
  }
  getPaymentReport():Observable<any>{
    return this.http.get(endpoint + 'payments_report/').pipe(
      map(this.extractData));
  }


  getVehicleReport():Observable<any>{
    return this.http.get(endpoint + 'vehicle_summary/',).pipe(
      map(this.extractData));
  }

  getTripSummaryReport():Observable<any>{
    return this.http.get(endpoint + 'trips_report_summary/',).pipe(
      map(this.extractData));
  }

  

  addEmployee(data:any):Observable<any>{
    return this.http.post(endpoint + 'users/',data).pipe(
      map(this.extractData));
  }

 
  addCompany(data:any):Observable<any>{
    return this.http.post(endpoint + 'company/',data).pipe(
      map(this.extractData));
  }

  electricityMeters(data:any):Observable<any>{
    return this.http.get(endpoint + 'electricity_meters/'+data).pipe(
      map(this.extractData));
  }
  switchMeters(data:any):Observable<any>{
    return this.http.post(endpoint + 'switch_meter/',data).pipe(
      map(this.extractData));
  }


  getPayments(data:any):Observable<any>{
    return this.http.get(endpoint + 'payments/'+data).pipe(
      map(this.extractData));
  }
  getWalletPayments(data:any):Observable<any>{
    return this.http.get(endpoint + 'wallet_payments/'+data).pipe(
      map(this.extractData));
  }

  uploadAgreements(data:any):Observable<any>{
    return this.http.post(endpoint + 'tenancy_agreements/',data).pipe(
      map(this.extractData));
  }

  
  getAgreements(data:any):Observable<any>{
    return this.http.get(endpoint + 'tenancy_agreements/'+data).pipe(
      map(this.extractData));
  }
  
  

  
  getMeterTokens(data:any):Observable<any>{
    return this.http.get(endpoint + 'meter-tokens/'+data).pipe(
      map(this.extractData));
  }
  createInvoice(data:any):Observable<any>{
    return this.http.post(endpoint + 'invoices/',data).pipe(
      map(this.extractData));
  }
  getInvoices(data:any):Observable<any>{
    return this.http.get(endpoint + 'invoices/'+data).pipe(
      map(this.extractData));
  }


  addPolicy(data:any):Observable<any>{
    return this.http.post(endpoint + 'leave_policy/',data).pipe(
      map(this.extractData));
  }


  getMonthlyTrips():Observable<any>{
    return this.http.get(endpoint + 'trips_monthly_summary/').pipe(
      map(this.extractData));
  }

  getTripsDocumennts(id:any):Observable<any>{
    return this.http.get(endpoint + 'trip_documents/'+id).pipe(
      map(this.extractData));
  }


  getTripsCounts():Observable<any>{
    return this.http.get(endpoint + 'trips_count_summary/').pipe(
      map(this.extractData));
  }

  getPolicy():Observable<any>{
    return this.http.get(endpoint + 'leave_policy/').pipe(
      map(this.extractData));
  }




  
  addExpenseType(data:any):Observable<any>{
    return this.http.post(endpoint + 'expense_types/',data).pipe(
      map(this.extractData));
  }


  delete(url: string, id: number | string): Observable<any> {
    const deleteUrl = `${endpoint}${url}/${id}`;  // Construct URL with the ID
    return this.http.delete(deleteUrl); // Perform the HTTP DELETE request
  }

  getExpenseTypes(text:""):Observable<any>{
    return this.http.get(endpoint + 'expense_types/'+text).pipe(
      map(this.extractData));
  }


  getTarrifs():Observable<any>{
    return this.http.get(endpoint + 'electricity_tariffs/').pipe(
      map(this.extractData));
  }
  addTarrifs(data:any):Observable<any>{
    return this.http.post(endpoint + 'electricity_tariffs/',data).pipe(
      map(this.extractData));
  }


  serviceTypeCategory(data:any):Observable<any>{
    return this.http.post(endpoint + 'service_types/',data).pipe(
      map(this.extractData));
  }

  getServiceTypes(data:any):Observable<any>{
    return this.http.get(endpoint + 'service_types/'+data).pipe(
      map(this.extractData));
  }




  
  areas(data:any):Observable<any>{
    return this.http.post(endpoint + 'areas/',data).pipe(
      map(this.extractData));
  }

  
  
  getAreas():Observable<any>{
    return this.http.get(endpoint + 'areas/').pipe(
      map(this.extractData));
  }

  addAllowance(data:any):Observable<any>{
    return this.http.post(endpoint + 'allowances/',data).pipe(
      map(this.extractData));
  }

  getAllowances():Observable<any>{
    return this.http.get(endpoint + 'allowances/').pipe(
      map(this.extractData));
  }
  addDeduction(data:any):Observable<any>{
    return this.http.post(endpoint + 'deductions/',data).pipe(
      map(this.extractData));
  
  }


  addDocument(data:any):Observable<any>{
    return this.http.post(endpoint + 'documents/',data).pipe(
      map(this.extractData));
  
  }

  addProductCategory(data:any):Observable<any>{
    return this.http.post(endpoint + 'product_category/',data).pipe(
      map(this.extractData));
  
  }

  deleteProductCategory(id:any):Observable<any>{
    return this.http.delete(endpoint + 'product_category/'+id+'/').pipe(
      map(this.extractData));
  
  }

  getPropertyCategorys(text:any):Observable<any>{
    return this.http.get(endpoint + 'property_categories/'+text).pipe(
      map(this.extractData));
  
  }

  addPropertyCategory(text:any):Observable<any>{
    return this.http.post(endpoint + 'property_categories/',text).pipe(
      map(this.extractData));
  
  }

  
  addPropertyTypes(data:any):Observable<any>{
    return this.http.post(endpoint + 'property_types/',data).pipe(
      map(this.extractData));
  
  }
  getPropertyTypes(data:any):Observable<any>{
    return this.http.get(endpoint + 'property_types/'+data).pipe(
      map(this.extractData));
  
  }

  getInvoiceTypes(data:any):Observable<any>{
    return this.http.get(endpoint + 'invoice_types/'+data).pipe(
      map(this.extractData));
  
  }

  
  getAccountTypes(data:any):Observable<any>{
    return this.http.get(endpoint + 'account_types/'+data).pipe(
      map(this.extractData));
  
  }

  addInvoiceTypes(data:any):Observable<any>{
    return this.http.post(endpoint + 'invoice_types/',data).pipe(
      map(this.extractData));
  
  }
  
  

  

  getAccounts(text:any):Observable<any>{
    return this.http.get(endpoint + 'accounts/'+text).pipe(
      map(this.extractData));
  }

  
  addAccounts(text:any):Observable<any>{
    return this.http.post(endpoint + 'accounts/',text).pipe(
      map(this.extractData));
  }

  addUnits(text:any):Observable<any>{
    return this.http.post(endpoint + 'units/',text).pipe(
      map(this.extractData));
  }
  getUnits(text:any):Observable<any>{
    return this.http.get(endpoint + 'units/'+text).pipe(
      map(this.extractData));
  }

  tenantsBulkUpload(data:any):Observable<any>{
    return this.http.post(endpoint + 'lease-upload/',data).pipe(
      map(this.extractData));
  }
  unitsBulkUpload(data:any):Observable<any>{
    return this.http.post(endpoint + 'units_bulk_upload/',data).pipe(
      map(this.extractData));
  }


  getUnitDetail(text:any):Observable<any>{
    return this.http.get(endpoint + 'unit_details/'+text).pipe(
      map(this.extractData));
  }
  getBrands(text:any):Observable<any>{
    return this.http.get(endpoint + 'brands/'+text).pipe(
      map(this.extractData));
  }

  getDocument(data:any):Observable<any>{
    return this.http.post(endpoint + 'documents/',data).pipe(
      map(this.extractData));
  
  }


  getDocuments():Observable<any>{
    return this.http.get(endpoint + 'documents/').pipe(
      map(this.extractData));
  
  }

  getDeductions():Observable<any>{
    return this.http.get(endpoint + 'deductions/').pipe(
      map(this.extractData));
  }


  createExpense(data:any):Observable<any>{
    return this.http.post(endpoint + 'expenses/',data).pipe(
      map(this.extractData));
  }

  getExpenses(data:any):Observable<any>{
    return this.http.get(endpoint + 'expenses/'+data).pipe(
      map(this.extractData));
  }

  addBranch(data:any):Observable<any>{
    return this.http.post(endpoint + 'branches/',data).pipe(
      map(this.extractData));
  }


  addProperty(data:any):Observable<any>{
    return this.http.post(endpoint + 'properties/',data).pipe(
      map(this.extractData));
  }

  getProperty(data:any):Observable<any>{
    return this.http.get(endpoint + 'properties/'+data).pipe(
      map(this.extractData));
  }

  addBlock(data:any):Observable<any>{
    return this.http.post(endpoint + 'blocks/',data).pipe(
      map(this.extractData));
  }


  getBlocks(data:any):Observable<any>{
    return this.http.get(endpoint + 'blocks/'+data).pipe(
      map(this.extractData));
  }

  
  updateBranch(data:any):Observable<any>{
    return this.http.put(endpoint + 'branches/'+data.id+'/',data).pipe(
      map(this.extractData));
  }

  addDepartment(data:any):Observable<any>{
    return this.http.post(endpoint + 'department/',data).pipe(
      map(this.extractData));
  }

  addRole(data:any):Observable<any>{
    return this.http.post(endpoint + 'role/',data).pipe(
      map(this.extractData));
  }


  getUsers(text:any):Observable<any>{
    return this.http.get(endpoint + 'users/'+text).pipe(
      map(this.extractData));
  }

  
  getUserDetails():Observable<any>{
    return this.http.get(endpoint + 'user_info/').pipe(
      map(this.extractData));
  }


  

  profileUpload(data:any):Observable<any>{
    return this.http.post(endpoint + 'profile_upload/',data).pipe(
      map(this.extractData));
  }




  getUser(id:any):Observable<any>{
    return this.http.get(endpoint + 'users/'+id+'/').pipe(
      map(this.extractData));
  }

  createFuelOrder(data:any):Observable<any>{
    return this.http.post(endpoint + 'fuel_orders/',data).pipe(
      map(this.extractData));
  }


  completeTrip(data:any):Observable<any>{
    return this.http.post(endpoint + 'complete_trip/',data).pipe(
      map(this.extractData));
  }
  
  createPurchaseOrder(data:any):Observable<any>{
    return this.http.post(endpoint + 'purchase_orders/',data).pipe(
      map(this.extractData));
  }
  getPurchaseOrder(data:any):Observable<any>{
    return this.http.get(endpoint + 'purchase_orders/'+data).pipe(
      map(this.extractData));
  }
  getFuelOrders(text:any):Observable<any>{
    return this.http.get(endpoint + 'fuel_orders/'+text).pipe(
      map(this.extractData));
  }

  getFuelOrdersImages(text:any):Observable<any>{
    return this.http.get(endpoint + 'fuel_order_images/'+text).pipe(
      map(this.extractData));
  }

  updateUser(id:any,data:any):Observable<any>{
    return this.http.put(endpoint + 'users/'+id+'/',data).pipe(
      map(this.extractData));
  }
  
  changePassword(data:any):Observable<any>{
    return this.http.post(endpoint + 'change_password/',data).pipe(
      map(this.extractData));
  }


  

  updateRole(data:any):Observable<any>{
    return this.http.put(endpoint + 'role/'+data.id+'/',data).pipe(
      map(this.extractData));
  }
  

  
  mapPayment(data:any):Observable<any>{
    return this.http.post(endpoint + 'payment_mapping/',data).pipe(
      map(this.extractData));
  }

  getRoles():Observable<any>{
    return this.http.get(endpoint + 'role/').pipe(
      map(this.extractData));
  }


    
  getProperties():Observable<any>{
    return this.http.get(endpoint + 'properties/').pipe(
      map(this.extractData));
  }


  addEmploymentType(data:any):Observable<any>{
    return this.http.post(endpoint + 'employment_type/',data).pipe(
      map(this.extractData));
  }

  getEmploymentTypes():Observable<any>{
    return this.http.get(endpoint + 'employment_type/').pipe(
      map(this.extractData));
  }
  updateEmploymentType(data:any):Observable<any>{
    return this.http.put(endpoint + 'employment_type/'+data.id+'/',data).pipe(
      map(this.extractData));
  }
  getBranchs():Observable<any>{
    return this.http.get(endpoint + 'branches/').pipe(
      map(this.extractData));
  }
  getBranch(id:any):Observable<any>{
    return this.http.get(endpoint + 'branches/'+id+'/').pipe(
      map(this.extractData));
  }
  
  getCompanies():Observable<any>{
    return this.http.get(endpoint + 'company/').pipe(
      map(this.extractData));
  }

  getCustomers(text:any):Observable<any>{
    return this.http.get(endpoint + 'customers/'+text).pipe(
      map(this.extractData));
  }

  addTenants(text:any):Observable<any>{
    return this.http.post(endpoint + 'tenants/',text).pipe(
      map(this.extractData));
  }

  
  getTenants(text:any):Observable<any>{
    return this.http.get(endpoint + 'tenants/'+text).pipe(
      map(this.extractData));
  }

  deleteCustomers(id:any):Observable<any>{
    return this.http.delete(endpoint + 'customers/'+id+'/').pipe(
      map(this.extractData));
  }

  addCustomer(data:any):Observable<any>{
    return this.http.post(endpoint + 'customers/',data).pipe(
      map(this.extractData));
  }
  

  addSupplier(data:any):Observable<any>{
    return this.http.post(endpoint + 'suppliers/',data).pipe(
      map(this.extractData));
  }

  updateSupplier(data:any):Observable<any>{
    return this.http.put(endpoint + 'suppliers/'+data+'/',data).pipe(
      map(this.extractData));
  }


  getSupplier(data:any):Observable<any>{
    return this.http.get(endpoint + 'suppliers/'+data).pipe(
      map(this.extractData));
  }
  
  

  addStation(data:any):Observable<any>{
    return this.http.post(endpoint + 'stations/',data).pipe(
      map(this.extractData));
  }
  

  getStations():Observable<any>{
    return this.http.get(endpoint + 'stations/').pipe(
      map(this.extractData));
  }
  getStation(id:any):Observable<any>{
    return this.http.get(endpoint + 'stations/'+id).pipe(
      map(this.extractData));
  }
  

  updateStation(data:any):Observable<any>{
    return this.http.put(endpoint + 'stations/'+data.id+'/',data).pipe(
      map(this.extractData));
  }
  
  deleteStation(id:any):Observable<any>{
    return this.http.delete(endpoint + 'stations/'+id+'/').pipe(
      map(this.extractData));
  }
  
  addStationRate(data:any):Observable<any>{
    return this.http.post(endpoint + 'station_rate/',data).pipe(
      map(this.extractData));
  }

  getStationRates(id:any):Observable<any>{
    return this.http.get(endpoint + 'station_rate/?station='+id).pipe(
      map(this.extractData));
  }
  addRoute(data:any):Observable<any>{
    return this.http.post(endpoint + 'routes/',data).pipe(
      map(this.extractData));
  }
  
  getRoutes():Observable<any>{
    return this.http.get(endpoint + 'routes/').pipe(
      map(this.extractData));
  }

  addTask(data:any):Observable<any>{
    return this.http.post(endpoint + 'tasks/',data).pipe(
      map(this.extractData));
  }

  getTasks(text:any):Observable<any>{
    return this.http.get(endpoint + 'tasks/'+text).pipe(
      map(this.extractData));
  }

  getIssues(text:any):Observable<any>{
    return this.http.get(endpoint + 'delay/'+text).pipe(
      map(this.extractData));
  }
  

  searchIssues(text:any):Observable<any>{
    return this.http.post(endpoint + 'delay/search/',text).pipe(
      map(this.extractData));
  }
  
  
  
  deleteIssues(text:any):Observable<any>{
    return this.http.delete(endpoint + 'delay/'+text+'/').pipe(
      map(this.extractData));
  }
  
  
  getTripDetail(id:any):Observable<any>{
    return this.http.get(endpoint + 'trip-detail/'+id+'/').pipe(
      map(this.extractData));
  }

  createTrip(data:any):Observable<any>{
    return this.http.post(endpoint + 'trips/',data).pipe(
      map(this.extractData));
  }

  
  updateTrip(data:any):Observable<any>{
    return this.http.put(endpoint + 'trips/'+data.id+'/',data).pipe(
      map(this.extractData));
  }
  getTrips(data:any):Observable<any>{
    return this.http.get(endpoint + 'trips/'+data).pipe(
      map(this.extractData));
  }

  searchTrips(data:any):Observable<any>{
    return this.http.post(endpoint + 'trips/search/',data).pipe(
      map(this.extractData));
  }

  getOrders():Observable<any>{
    return this.http.get(endpoint + 'orders/').pipe(
      map(this.extractData));
  }

  getTask(id:any):Observable<any>{
    return this.http.get(endpoint + 'tasks/'+id+'/').pipe(
      map(this.extractData));
  }
  deleteTask(id:any):Observable<any>{
    return this.http.delete(endpoint + 'tasks/'+id+'/').pipe(
      map(this.extractData));
  }

  deleteRoute(id:any):Observable<any>{
    return this.http.delete(endpoint + 'routes/'+id+'/').pipe(
      map(this.extractData));
  }
  
  addProduct(data:any):Observable<any>{
    return this.http.post(endpoint + 'products/',data).pipe(
      map(this.extractData));
  }


  updateProduct(data:any):Observable<any>{
    return this.http.put(endpoint + 'products/'+data.id+'/',data).pipe(
      map(this.extractData));
  }

  addVehicle(data:any):Observable<any>{
    return this.http.post(endpoint + 'vehicles/',data).pipe(
      map(this.extractData));
  }

  addTrailer(data:any):Observable<any>{
    return this.http.post(endpoint + 'trailers/',data).pipe(
      map(this.extractData));
  }
  getTrailer(data:any):Observable<any>{
    return this.http.get(endpoint + 'trailers/'+data).pipe(
      map(this.extractData));
  }

  updateTrailer(data:any):Observable<any>{
    return this.http.put(endpoint + 'trailers/'+data.id+'/',data).pipe(
      map(this.extractData));
  }

  

  

  getVehicle(id:any):Observable<any>{
    return this.http.get(endpoint + 'vehicles/'+id+'/').pipe(
      map(this.extractData));
  }



  
  updateVehicle(data:any):Observable<any>{
    return this.http.put(endpoint + 'vehicles/'+data.id+'/',data).pipe(
      map(this.extractData));
  }


  addFile(data:any):Observable<any>{
    return this.http.post(endpoint + 'files/',data).pipe(
      map(this.extractData));
  }
  getFiles(data:any):Observable<any>{
    return this.http.get(endpoint + 'files/'+data).pipe(
      map(this.extractData));
  }
  getVehicles(text:any):Observable<any>{
    return this.http.get(endpoint + 'vehicles/'+text).pipe(
      map(this.extractData));
  }


  getProducts(text:any):Observable<any>{
    return this.http.get(endpoint + 'products/'+text).pipe(
      map(this.extractData));
  }
  

  createJobcard(data:any):Observable<any>{
    return this.http.post(endpoint + 'jobcard/',data).pipe(
      map(this.extractData));
  }

  addJobCardItem(data:any){
    return this.http.post(endpoint + 'add_jobcard_item/',data).pipe(
      map(this.extractData));
  }

  
  jobcardApprovalRequest(data:any){
    return this.http.post(endpoint + 'request_jobcard_approval/',data).pipe(
      map(this.extractData));
  }

  
  editJobCardItem(data:any){
    return this.http.post(endpoint + 'update_jobcard_item/',data).pipe(
      map(this.extractData));
  }


  completeJobCard(data:any){
    return this.http.post(endpoint + 'complete_jobcard/',data).pipe(
      map(this.extractData));
  }

  deleteJobCardItem(data:any){
    return this.http.post(endpoint + 'delete_jobcard_item/',data).pipe(
      map(this.extractData));
  }
  
  cancelJobCardItem(data:any){
    return this.http.post(endpoint + 'cancel_jobcard/',data).pipe(
      map(this.extractData));
  }

  getJobcards(text:any):Observable<any>{
    return this.http.get(endpoint + 'jobcard/'+text).pipe(
      map(this.extractData));
  }

  searchJobcards(text:any):Observable<any>{
    return this.http.post(endpoint + 'jobcard/search/',text).pipe(
      map(this.extractData));
  }
  getJobcardItems(text:any):Observable<any>{
    return this.http.get(endpoint + 'jobcard_items/'+text).pipe(
      map(this.extractData));
  }

  getPurchaseItems(text:any){
    return this.http.get(endpoint + 'order_items/'+text).pipe(
      map(this.extractData));
  }
  addPurchaseItems(data:any){
    return this.http.post(endpoint + 'order_items/',data).pipe(
      map(this.extractData));
  }

  updatePurchaseItems(data:any){
    return this.http.put(endpoint + 'order_items/'+data.id+'/',data).pipe(
      map(this.extractData));
  }

  deletePurchaseItems(id:any){
    return this.http.delete(endpoint + 'order_items/'+id+'/').pipe(
      map(this.extractData));
  }


  receivePurchase(data:any){
    return this.http.post(endpoint + 'receive_order/',data).pipe(
      map(this.extractData));
  }

  

  leaveRequest(data:any):Observable<any>{
    return this.http.post(endpoint + 'leave-requests/',data).pipe(
      map(this.extractData));
  }
  

  

  getLeaveRequest():Observable<any>{
    return this.http.get(endpoint + 'leave-requests/').pipe(
      map(this.extractData));
  }
  
  getLeaveRequestByStatus(text:any):Observable<any>{
    return this.http.get(endpoint + 'leave-requests/?status='+text).pipe(
      map(this.extractData));
  }
  
  getDepartments():Observable<any>{
    return this.http.get(endpoint + 'department/').pipe(
      map(this.extractData));
  }
  
  private notify$ = new BehaviorSubject<Record<string, any>>({});

  getOptions() {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.notify$.next(this.options);
  }

  private options = defaults;

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }
}
