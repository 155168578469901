import { NavItem } from './nav-item/nav-item';

export const operationsItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'aperture',
    route: '/dashboards/dashboard1',
  },
 
  {
    navCap: 'Quick Actions',
  },

  
  {
    displayName: 'Blocks',
    iconName: 'building',
    route: 'apps/blocks',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/blocks/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/blocks/list',
      }
    ],
  },


  {
    displayName: 'Units',
    iconName: 'building-skyscraper',
    route: 'apps/units',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/units/add',
      },
      {
        displayName: 'Units list',
        iconName: 'point',
        route: 'apps/units/list',
      }
    ],
  },
  {
    displayName: 'House Owners',
    iconName: 'user-cog',
    route: 'apps/houseowners',
    children: [
     
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/houseowners/list',
      }
    ],
  },
  {
    displayName: 'Tenants',
    iconName: 'users-group',
    route: 'apps/tenants',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/tenants/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/tenants/list',
      }
    ],
  },


  {
    navCap: 'Finance',
  },

  {
    displayName: 'Transactions',
    iconName: 'coin',
    route: 'apps/transactions',
    children: [
      {
        displayName: 'Create Invoice',
        iconName: 'point',
        route: 'apps/transactions/create-invoice',
      },
      {
        displayName: 'Recurrring Invoice',
        iconName: 'point',
        route: 'apps/transactions/recurring-invoices',
      },
      {
        displayName: 'Invoice List',
        iconName: 'point',
        route: 'apps/transactions/invoices',
      },
      {
        displayName: 'Credit Note',
        iconName: 'point',
        route: 'apps/transactions/credit-note',
      },
     
    ],
  },
  {
    displayName: 'Payments',
    iconName: 'cash',
    route: 'apps/payments',
    children: [
      {
        displayName: 'Payments',
        iconName: 'point',
        route: 'apps/payments/payments',
      },
      {
        displayName: 'Suspense Accounts',
        iconName: 'point',
        route: 'apps/payments/suspense',
      }
    ],
  },
  {
    displayName: 'Expense',
    iconName: 'receipt-2',
    route: 'apps/expenses',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/expenses/add',
      },
      {
        displayName: 'Expenses',
        iconName: 'point',
        route: 'apps/expenses/list',
      },
      
    ],
  },


  {
    displayName: 'Service Providers',
    iconName: 'user-search',
    route: 'apps/suppliers',
    children: [
    
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/suppliers/list',
      }
    ],
  },



  {
    navCap: 'Utility Billing',
  },

  {
    displayName: 'Water',
    iconName: 'droplet',
    route: 'apps/utility/water',
  },
  {
    displayName: 'Electricty Tarrifs',
    iconName: 'bolt',
    route: 'apps/utility/electricity-tarrifs',
  },
  
  {
    displayName: 'Electricty',
    iconName: 'bolt',
    route: 'apps/utility/electricity',
  },
  
  {
    navCap: 'Reports',
  },

  {
    displayName: 'Reports',
    iconName: 'file-report',
    route: 'apps/reports',
    children: [
      {
        displayName: 'Finance Summary',
        iconName: 'point',
        route: 'apps/reports/finance-summary',
      },
      {
        displayName: 'Rental Collection Report',
        iconName: 'point',
        route: 'apps/reports/rental-collection',
      }
    ],
  },


  {
    navCap: 'Services',
  },

  {
    displayName: 'Service Request',
    iconName: 'tool',
    route: 'apps/services/requests',
  },
  
  
  

  {
    navCap: 'Settings',
  },

 

  {
    displayName: 'Properties',
    iconName: 'building-estate',
    route: 'apps/properties',
    children: [
      {
        displayName: 'Add Property',
        iconName: 'point',
        route: 'apps/properties/add',
      },
      {
        displayName: 'Properties',
        iconName: 'point',
        route: 'apps/properties/list',
      }
    ],
  },

 
  {
    displayName: 'Documents',
    iconName: 'books',
    route: 'apps/documents',
    children: [
    
      {
        displayName: 'Documents List',
        iconName: 'point',
        route: 'apps/documents/list',
      }
    ],
  },


  {
    displayName: 'Account Setting',
    iconName: 'user-circle',
    route: 'apps/users/account',
  },


 

];
